import React from "react";
import "../Css/CopyRight.css"
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


    

function CopyRight() {
   const { t } = useTranslation();
   
    return (
        <div className="copyright" >
            <h1>Copyright © 2024 Helikon. All rights reserved.</h1>
<p>1. **Copyright Notice:** 
   The content, design, graphics, text, images, videos, audio, and other materials on this website are protected by copyright laws. All rights are owned by Helikon unless otherwise indicated.
</p>
<p>
2. **Use of Content:**
   You may not reproduce, distribute, modify, transmit, display, perform, or create derivative works from any part of this website without the prior written permission of Helikon. Unauthorized use or reproduction of this website's content is strictly prohibited and may be subject to legal action.
   </p>
   <p>
3. **Trademarks:**
   All trademarks, service marks, logos, and trade names on this website are the property of Helikon or their respective owners. You may not use, reproduce, or display any of these marks without the prior written consent of the trademark owner.
   </p>
   <p>
4. **Downloading Materials:**
   You may download and print a copy of the materials on this website for personal, non-commercial use only, provided that you do not modify or alter the content in any way and keep all copyright and other proprietary notices intact.
   </p>
   <p>
5. **Third-Party Content:**
   Some parts of this website may contain content provided by third parties, such as user-generated content or links to external websites. Helikon does not endorse, control, or take responsibility for third-party content, and any use of such content is at your own risk.
   </p>
   <p>
6. **No Warranty:**
   This website is provided "as is" without any warranties, express or implied. Helikon makes no representations or warranties regarding the accuracy, completeness, or reliability of the content on this website or its suitability for any purpose.
   </p>
   <p>
7. **Limitation of Liability:**
   Helikon and its affiliates, officers, directors, employees, or agents shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use this website or the content, even if advised of the possibility of such damages.
   </p>
   <p>
8. **Governing Law:**
   This copyright notice shall be governed by and construed in accordance with the laws of EU nation, and any disputes relating to this notice or the use of this website shall be subject to the exclusive jurisdiction of the courts of Estonia.
   </p>
   <p>
9. **Changes to Terms:**
   Helikon reserves the right to change, modify, or revise these terms and conditions at any time without notice. Your continued use of this website following the posting of any changes constitutes acceptance of those changes.
   </p>
   <p>
10. **Contact Information:**
    If you have any questions or concerns regarding this copyright notice, please contact us at Info@helikon.ee.
    </p>
Thank you for visiting our website and respecting our rights and policies.
<div>
<p><Link to="/:lang/privaatsuspoliitika">{t('jalus.footerPrivacyPolicy')}</Link></p>
</div>

        </div>
    );
}

export default CopyRight;
