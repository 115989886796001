// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'; // Firestore for Database
import { getAuth } from 'firebase/auth'; // Firebase Authentication
import { getStorage } from 'firebase/storage'; // Firebase Storage
import { getAnalytics } from 'firebase/analytics'; // Firebase Analytics (optional)

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCnD95r_sbj0ASvIlSadBC9C48ya9yYtLc",
    authDomain: "leht-78dd7.firebaseapp.com",
    projectId: "leht-78dd7",
    storageBucket: "leht-78dd7.appspot.com",
    messagingSenderId: "999386612993",
    appId: "1:999386612993:web:1da8de0f0166f1d6080700",
    measurementId: "G-7B81SY12W8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(app);  // Firestore Database
const auth = getAuth(app);     // Firebase Authentication
const storage = getStorage(app);  // Firebase Storage
const analytics = getAnalytics(app);  // Firebase Analytics (optional)

// Export the Firebase services for use in your components
export { db, auth, storage, analytics };