import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import "../Css/Navbar.css";
import logo from "../Pictures/HelikonLogo4.webp"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isLangOpen, setIsLangOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Load language from LocalStorage
    useEffect(() => {
        const savedLang = localStorage.getItem('i18nextLng'); 
        if (savedLang && savedLang !== i18n.language) {
            i18n.changeLanguage(savedLang);
        }
    }, [i18n]);

    // Change language function
    const changeLanguage = (lang) => {
        if (i18n.language === lang) return; 

        localStorage.setItem('i18nextLng', lang);
        i18n.changeLanguage(lang).then(() => {
            const currentPath = location.pathname.split('/');
            const newPath = `/${lang}/${currentPath.slice(2).join('/')}`;
            navigate(newPath, { replace: true });
        });

        // Sulgeme keelemenüü pärast valikut
        setIsLangOpen(false);
    };

    return (
        <nav className="navbar">
            <NavLink to={`/${i18n.language}`} className="nav-menu-item logo">
                <img src={logo} alt="Home" className="logo-image" />
            </NavLink>
            <button className="menu-toggle" onClick={() => setIsOpen(!isOpen)}>
                <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="2x" />
            </button>
            <ul className={`nav-menu-list ${isOpen ? "open" : ""}`}>
                <li className="nav-menu-item">
                    <NavLink to={`/${i18n.language}/teenused`} className={({ isActive }) => (isActive ? "active" : "inactive")}>{t('navbar.services')}</NavLink>
                </li>
                <li className="nav-menu-item">
                    <NavLink to={`/${i18n.language}/meist`} className={({ isActive }) => (isActive ? "active" : "inactive")}>{t('navbar.aboutUs')}</NavLink>
                </li>
                <li className="nav-menu-item">
                    <NavLink to={`/${i18n.language}/kontakt`} className={({ isActive }) => (isActive ? "active" : "inactive")}>{t('navbar.contact')}</NavLink>
                </li>

                {/* Keelevalikute menüü */}
                <li className="nav-menu-item language-dropdown">
                <button className="language-button" onClick={() => setIsLangOpen(!isLangOpen)}>
                            <img src={`/Lipud/${i18n.language}.webp`} alt={i18n.language} className="language-icon" />
                        </button>
                    {isLangOpen && (
                        <ul className="language-options">
                            <li onClick={() => changeLanguage('et')}>
                                <img src="/Lipud/et.webp" alt="Eesti" className="language-icon" />
                                Eesti
                            </li>
                            <li onClick={() => changeLanguage('en')}>
                                <img src="/Lipud/en.webp" alt="English" className="language-icon" />
                                English
                            </li>
                            <li onClick={() => changeLanguage('ru')}>
                                <img src="/Lipud/ru.webp" alt="Русский" className="language-icon" />
                                Русский
                            </li>
                            <li onClick={() => changeLanguage('fi')}>
                                <img src="/Lipud/fi.webp" alt="Finland" className="language-icon" />
                                Suomi
                            </li>
                            <li onClick={() => changeLanguage('lv')}>
                                <img src="/Lipud/lv.webp" alt="Latvia" className="language-icon" />
                                Latviešu
                            </li>
                            <li onClick={() => changeLanguage('lt')}>
                                <img src="/Lipud/lt.webp" alt="Lithuania" className="language-icon" />
                                Lietuvių
                            </li>
                        </ul>
                    )}
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
