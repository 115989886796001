import React from 'react';
import ReactDOM from 'react-dom/client';
import './Css/index.css';
import App from './App';
import './firebase';  // Jäta see, kui Firebase on kasutusel
import './i18n';  // Jäta see, sest see haldab i18n (rahvusvahelistamist)

// React 18 ja root renderdamine
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
