import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Keele valikud
import translationEN from './Pictures/en.json';
import translationET from './Pictures/et.json';
import translationRU from './Pictures/ru.json';
import translationFI from './Pictures/fi.json';
import translationLV from './Pictures/lv.json'; // Läti keele tõlge
import translationLT from './Pictures/lt.json'; // Leedu keele tõlge

// Funktsioon keele hankimiseks localStorage'st
const getInitialLanguage = () => {
  const savedLanguage = localStorage.getItem('i18nextLng');
  return savedLanguage || 'et'; // Vaikimisi keel on 'et'
};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      et: { translation: translationET },
      ru: { translation: translationRU },
      fi: { translation: translationFI },
      lv: { translation: translationLV }, // Läti keel
      lt: { translation: translationLT }, // Leedu keel
    },
    lng: getInitialLanguage(), // Kasuta alati localStorage keelt
    fallbackLng: 'et', // Kui localStorage keelt pole saadaval, kasuta 'et'
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
