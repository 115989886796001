import React from 'react';
import '../Css/Haiglad.css';
import Footer from '../Components/Footer';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import HospitalBed from '../Pictures/hospital-bed.png';
import RodOfAsclepius from '../Pictures/rod-of-asclepius.png';
import Monitor from '../Pictures/monitor.png';

function Haiglad() {
    const { t, i18n } = useTranslation(); // Kasuta i18n keele jaoks

  return (
    <>
      <div className="container">
        <div className="container content">
          <div>
            <h1>{t('hospital.title')}</h1>
          </div>
          <div className="icon-with-text">
            <img src={RodOfAsclepius} alt="Welcome" className="icon" />
            <p>
              <b>{t('hospital.textBold')}</b><br />
              {t('hospital.text')}
            </p>
          </div>
          <div className="icon-with-text">
            <img src={Monitor} alt="Commitment" className="icon" />
            <p>
              <b>{t('hospital.text2Bold')}</b><br />
              {t('hospital.text2')}
            </p>
          </div>
          <div className="icon-with-text">
            <img src={HospitalBed} alt="Mission" className="icon" />
            <div dangerouslySetInnerHTML={{ __html: t('hospital.text3') }} />
          </div>
          <div>
            <div dangerouslySetInnerHTML={{ __html: t('hospital.text4') }} />
          </div>
          <div>
            <div dangerouslySetInnerHTML={{ __html: t('hospital.text5') }} />
          </div>
          <div className="button-container">
            <Link to={`/${i18n.language}/kontakt`} className="custom-button">{t('hospital.nupp')}</Link>
            </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Haiglad;
