import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../Css/Avaleht.css';
import Footer from "../Components/Footer";
import LogosLoop from '../Components/kaheksa';

function Avaleht() {
    const { i18n, t } = useTranslation();
    const [kategooriad, setKategooriad] = useState([]);  // Kategooriate array
    const [laadimisViga, setLaadimisViga] = useState(null);  // Veateade
    const [loading, setLoading] = useState(true);  // Laadimisolek
    const navigate = useNavigate();

    // Logime keele muutuse
    useEffect(() => {
        console.log("Praegune keel Avaleht lehel:", i18n.language);
    }, [i18n.language]);

    // Pakkige 'loadCategoriesData' funktsioon 'useCallback' sisse
    const loadCategoriesData = useCallback(async () => {
        try {
            setLoading(true);  // Seame laadimise olekuks "true"

            // Dünaamiliselt laeme JSON faili vastavalt hetke keelele
            const loadedKategooriad = await import(`../Data/tooted${i18n.language.toLowerCase()}.json`);
            
            console.log("Laaditud kategooriad:", loadedKategooriad.default);

            setKategooriad(loadedKategooriad.default);  // Seame laaditud kategooriad
            setLoading(false);  // Seame laadimise olekuks "false", kui andmed on laetud
        } catch (error) {
            console.error("Viga kategooriate andmete laadimisel:", error);
            setLaadimisViga("Kategooriaid ei õnnestunud laadida. Palun proovige uuesti.");
            setLoading(false);  // Kui on viga, seame laadimise olekuks "false"
        }
    }, [i18n.language]);  // See sõltub ainult keele muutusest

    // UseEffect, et laadida kategooriad, kui keel muutub
    useEffect(() => {
        loadCategoriesData();  // Käivitame kategooriate laadimise
    }, [i18n.language, loadCategoriesData]);  // Lisame 'loadCategoriesData' sõltuvuste hulka

    // Funktsioon kategooria kliki jaoks ja õigesse lehte suunamiseks
    const handleCategoryClick = (categoryId, categoryName) => {
        const encodedCategoryName = encodeURIComponent(categoryName);

        // Salvestame valitud kategooria ID sessioonis, et see oleks kättesaadav teistel lehtedel
        sessionStorage.setItem('selectedCategoryId', categoryId);
        console.log("Kategooria ID salvestatud sessioonis:", categoryId);

        // Kontrollime, kas klikitud kategooria on "Haiglad" (kontrollime numbrina, mitte stringina)
        if (categoryId === 4) {  // Eeldusel, et ID on number
            navigate(`/${i18n.language}/${encodedCategoryName}`);
        } else {
            navigate(`/${i18n.language}/tooted/${encodedCategoryName}`);
        }
    };

    return (
        <div className="avaleht">
            <div className="categories-container">
                {console.log("Renderdatavad kategooriad:", kategooriad)}

                {loading ? (
                    <p>Laadime kategooriaid...</p>
                ) : kategooriad.length > 0 ? (
                    kategooriad.map((category) => (
                        <div 
                            key={category.id} 
                            className="category" 
                            onClick={() => handleCategoryClick(category.id, category.nimi)}
                        >
                            <img src={`${process.env.PUBLIC_URL}/${category.pilt}`} alt={category.nimi} className="category-image" />
                            <h3 className="category-name">{category.nimi}</h3>
                        </div>
                    ))
                ) : (
                    <p>{laadimisViga || 'Kategooriaid ei leitud.'}</p>
                )}
            </div>

            <section className="about">
                <h1>
                    <span className="main-text">Non Vi Sed Arte</span>
                    <span className="translation"> -  "{t('avaLeht.kirjeldus4')}"</span>
                </h1>
                <h2>
                    {t('avaLeht.kirjeldus5')}<br />
                    {t('avaLeht.kirjeldus6')}<br />
                    {t('avaLeht.kirjeldus7')}<br />
                </h2>
            </section>

            <div className="logos-container">
                <LogosLoop />
            </div>

            <Footer />
        </div>
    );
}

export default Avaleht;
