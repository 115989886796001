import React from 'react';
import '../Css/Teenused.css'; // Make sure to include styles for .service-icon and .service-text
import Footer from '../Components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStethoscope, faUserMd, faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function Teenused() {

  const { t } = useTranslation();

  return (
    <div>
      <div className='taide' ></div>
    <>
      <main className="teenused">
        <section className="services">
          <h1 className='tekst1'>{t('servicesPage.servicesTitle')}</h1>
          <div className='tekst'>
            {t('servicesPage.introText')}
          </div>
    
          <div className="services-list">
            <Service
              icon={faStethoscope}
              title={t('servicesPage.service1Title')}
              description={t('servicesPage.service1Description')}
            />
            <Service
              icon={faUserMd}
              title={t('servicesPage.service2Title')}
              description={t('servicesPage.service2Description')}
            />
            <Service
              icon={faChalkboardTeacher}
              title={t('servicesPage.service3Title')}
              description={t('servicesPage.service3Description')}
            />
          </div>
        </section>
      </main>
      <div className='taide'></div>
      <Footer></Footer>
    </>
    
    </div>

  );
}

// Updated Service component for icon size and positioning text next to the icon
function Service({ icon, title, description }) {
  return (
    <article className="service">
      <div className="service-content">
        <FontAwesomeIcon icon={icon} size="2x" className="service-icon" /> {/* Adjust icon size */}
        <div className="service-text">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </article>
  );
}

export default Teenused;
