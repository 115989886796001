import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, Link } from 'react-router-dom';
import '../Css/Tooted.css';
import Footer from "../Components/Footer.js";

// Static imports for language-based data files
import tootedEt from '../Data/tootedet.json';
import tootedEn from '../Data/tooteden.json';
import tootedRu from '../Data/tootedru.json';
import tootedFi from '../Data/tootedfi.json';
import tootedLv from '../Data/tootedlv.json';
import tootedLt from '../Data/tootedlt.json';

// Example category name translations across languages
const categoryTranslations = {
    'Meditsiiniseadmed': {
        en: 'Medical Devices',
        ru: 'Медицинское оборудование',
        fi: 'Lääketieteelliset laitteet'
    },
    'Kulutarvikud': {
        en: 'Consumables',
        ru: 'Расходные материалы',
        fi: 'Kulutustarvikkeet'
    },
    'Veterinaariaseadmed': {
        en: 'Veterinary Equipment',
        ru: 'Ветеринарное оборудование',
        fi: 'Eläinlääketieteelliset laitteet'
    }
};

function Tooted() {
    const { i18n } = useTranslation();
    const [kategooriad, setKategooriad] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(() => sessionStorage.getItem('selectedCategoryId') || '');
    const [laadimisViga, setLaadimisViga] = useState(null);
    const navigate = useNavigate();
    const { categoryName } = useParams(); // Get category name from URL

    // Function to load product data based on language
    const loadTootedData = () => {
        try {
            let loadedKategooriad;

            // Load the correct data file based on the current language
            switch (i18n.language.toLowerCase()) {
                case 'et':
                    loadedKategooriad = tootedEt;
                    break;
                case 'en':
                    loadedKategooriad = tootedEn;
                    break;
                case 'ru':
                    loadedKategooriad = tootedRu;
                    break;
                case 'fi':
                    loadedKategooriad = tootedFi;
                    break;
                case 'lv':
                    loadedKategooriad = tootedLv;
                    break;
                case 'lt':
                    loadedKategooriad = tootedLt;
                    break;
                default:
                    loadedKategooriad = tootedEt; // Default to Estonian
                    break;
            }

            setKategooriad(loadedKategooriad);

            // Set default category if none is selected
            if (!sessionStorage.getItem('selectedCategoryId') && loadedKategooriad.length > 0) {
                const defaultCategoryId = loadedKategooriad[0].id.toString();
                setSelectedCategoryId(defaultCategoryId);
                sessionStorage.setItem('selectedCategoryId', defaultCategoryId);
            }
        } catch (error) {
            console.error("Error loading product data:", error);
            setLaadimisViga("Failed to load products. Please try again.");
        }
    };

    // Reload data every time language or category changes
    useEffect(() => {
        loadTootedData();  // Re-load products when language changes
    }, [i18n.language, categoryName]);  // Re-run when language or categoryName changes

    // Handle product click event
    const handleProductClick = (productId, productName) => {
        const encodedProductName = encodeURIComponent(productName);
        navigate(`/${i18n.language}/toote-detailid/${productId}/${encodedProductName}`);
    };

    const handleCategoryChange = (categoryId, categoryName) => {
        setSelectedCategoryId(categoryId);
        sessionStorage.setItem('selectedCategoryId', categoryId);
    
        if (categoryId === "4") {
            // Suunamine haiglate kategooria puhul
            const translatedCategoryName = categoryTranslations[categoryName]?.[i18n.language] || categoryName;
            navigate(`/${i18n.language}/haiglad`);  // Siin võid vajadusel muuta URL-i "hospitals" või mis iganes vajalik
        } else {
            // Tavaline suunamine muude kategooriate puhul
            const translatedCategoryName = categoryTranslations[categoryName]?.[i18n.language] || categoryName;
            navigate(`/${i18n.language}/tooted/${encodeURIComponent(translatedCategoryName)}`);
        }
    };
    

    // New useEffect: Handle language change and get category from sessionStorage
    useEffect(() => {
        const savedCategoryId = sessionStorage.getItem('selectedCategoryId');
        
        if (savedCategoryId && kategooriad.length > 0) {
            // Find the category object based on the saved ID
            const category = kategooriad.find(cat => cat.id.toString() === savedCategoryId);

            if (category) {
                // Translate category name based on the current language
                const translatedCategoryName = categoryTranslations[category.nimi]?.[i18n.language] || category.nimi;

                // Update the URL with the translated category name
                navigate(`/${i18n.language}/tooted/${translatedCategoryName}`, { replace: true });
            }
        }
    }, [i18n.language, kategooriad, navigate]);

    // Find the currently selected category based on URL params and log
    const selectedCategory = kategooriad?.find(category => 
        category?.nimi?.toLowerCase() === decodeURIComponent(categoryName)?.toLowerCase()
    );

    useEffect(() => {
        console.log("Laaditud kategooriad:", kategooriad);
        console.log("Otsin kategooriat URL-i järgi:", decodeURIComponent(categoryName));

        if (kategooriad.length > 0 && !selectedCategory) {
            setLaadimisViga(true); // Kui kategooriat ei leita
            console.log("Kategooriat ei leitud!");
        }
    }, [kategooriad, categoryName, selectedCategory]);

    if (!selectedCategory) {
        return (
            <div className="loading-message">
                <div className="animation-container"></div>
                <p>Loading products, please wait...</p>
                <button onClick={() => window.location.reload()} className="refresh-button">Refresh</button>
                <p>If the page doesn't load, <Link to={`/${i18n.language}/kontakt`}>contact us</Link>.</p>
            </div>
        );
    }

    return (
        <div className="toodete-leht">
            <div className='katNimi'>
                <h1>{selectedCategory?.nimi}</h1>
            </div>
            <div className='kategooriaNupud'>
                {kategooriad?.length > 0 && kategooriad.map(category => (
                    <button 
                        key={category?.id} 
                        className={`kategooriaNupp ${selectedCategoryId === category?.id?.toString() ? 'active' : ''}`}
                        onClick={() => handleCategoryChange(category?.id?.toString(), category?.nimi)}
                    >
                        {category?.nimi}
                    </button>
                ))}
            </div>
            <div className="selectedKategooria">
                <div className="toodeteNimekiri">
                    {selectedCategory?.tooted?.length > 0 ? (
                        selectedCategory.tooted.map(toode => (
                            <div key={toode.id} className="toode" onClick={() => handleProductClick(toode.id , toode.nimi)}>
                                <h3>{toode?.nimi}</h3>
                                <img src={`${process.env.PUBLIC_URL}/${toode?.pilt}`} alt={toode?.nimi} />
                                {toode?.hind && <h4>{toode?.hind}</h4>}
                                <p>{toode?.kirjeldus?.length > 150 ? `${toode.kirjeldus.substring(0, 150)}...` : toode.kirjeldus}</p>
                            </div>
                        ))
                    ) : (
                        <p>No products found.</p>
                    )}
                </div>
                {laadimisViga && (
                    <div>
                        <p>{laadimisViga}</p>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
}

export default Tooted;
