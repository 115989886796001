import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import i18n from './i18n'; // Importige i18next konfiguratsioon
import Avaleht from "./pages/Avaleht";
import Teenused from "./pages/Teenused";
import Kontakt from "./pages/Kontakt";
import Haiglad from "./pages/Haiglad";
import Tooted from "./pages/Tooted";
import TooteDetailid from './pages/TooteDetailid';
import Meist from "./pages/Meist";
import CopyRight from "./Components/CopyRight";
import Kasutustingimused from "./Components/Kasutustingimused";
import Privaatsuspoliitika from "./Components/Privaatsuspoliitika";
import Navbar from "./Components/Navbar";
import EditProduct from './Admin/EditProduct';
import "./Css/App.css";

const LanguageWrapper = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Kontrollime localStorage'it, kas seal on salvestatud keel
    const savedLanguage = localStorage.getItem('i18nextLng') || 'et'; // Vaikimisi keel on 'et'

    // Seadistame i18nexti keele ja navigeerime õigele URL-ile
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage); // Muudame keele i18next-is
    }

    // Uuendame URL-i, kui see pole vastavuses localStorage keele väärtusega
    const currentLangInUrl = window.location.pathname.split('/')[1]; // Saab URL-is oleva keele parameetri
    if (currentLangInUrl !== savedLanguage) {
      navigate(`/${savedLanguage}${window.location.pathname.substring(currentLangInUrl.length + 1)}`, { replace: true });
    }
  }, [navigate]);

  return <>{children}</>; // Tagasta komponendid pärast keele seadmist
};

function App() {
  return (
    <Router>
      <div className="app-container">
        <Navbar />
        <Routes>
          <Route path="/" element={<LanguageWrapper><Avaleht /></LanguageWrapper>} />
          <Route path="/:lang" element={<LanguageWrapper><Avaleht /></LanguageWrapper>} />
          <Route path="/:lang/tooted" element={<LanguageWrapper><Tooted /></LanguageWrapper>} />
          <Route path="/:lang/tooted/:categoryName" element={<LanguageWrapper><Tooted /></LanguageWrapper>} />
          <Route path="/:lang/toote-detailid/:productId/:productName" element={<LanguageWrapper><TooteDetailid /></LanguageWrapper>} />
          <Route path="/:lang/teenused" element={<LanguageWrapper><Teenused /></LanguageWrapper>} />
          <Route path="/:lang/meist" element={<LanguageWrapper><Meist /></LanguageWrapper>} />
          <Route path="/:lang/kontakt" element={<LanguageWrapper><Kontakt /></LanguageWrapper>} />
          <Route path="/:lang/:categoryName" element={<LanguageWrapper><Haiglad /></LanguageWrapper>} />
          <Route path="/:lang/copyright" element={<LanguageWrapper><CopyRight /></LanguageWrapper>} />
          <Route path="/:lang/kasutustingimused" element={<LanguageWrapper><Kasutustingimused /></LanguageWrapper>} />
          <Route path="/:lang/privaatsuspoliitika" element={<LanguageWrapper><Privaatsuspoliitika /></LanguageWrapper>} />
          <Route path="/admin/edit/:id" element={<LanguageWrapper><EditProduct /></LanguageWrapper>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
