import React, { useState, useEffect } from 'react';
import '../Css/kaheksa.css';
import { motion } from 'framer-motion';
import logos from '../Data/logos.json'; 

  
const LogosLoop = () => {
    // Valib logod, mis ei kuulu alumisse ritta
    const topLogos = logos.filter(logo => !logo.src.includes('Dheart.webp') && !logo.src.includes('livetec.webp') && !logo.src.includes('aktiia.webp'));
  
    // Valib logod alumisele reale, sealhulgas uue logo
    const bottomLogos = logos.filter(logo => logo.src.includes('Dheart.webp')|| logo.src.includes('aktiia.webp') || logo.src.includes('livetec.webp') );
  
    // Järgneb komponendi renderdamise osa
        const [screenWidth, setScreenWidth] = useState(window.innerWidth);
      
        useEffect(() => {
          const handleResize = () => {
            setScreenWidth(window.innerWidth);
          };
      
          window.addEventListener('resize', handleResize);
      
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }, []);
      
        const logoRowStyle = {
          margin: screenWidth < 1568 ? '20px 0' : '40px 0', // Adjust these values as needed
        };

    return (
        <div className="logos-container">
            <div className="logos-row" style={logoRowStyle}>
                {topLogos.map(logo => (
                    <motion.div
                        key={logo.id}
                        className="logo-motion-div"
                        initial={{ y: -1000, scale: 1.5 }}
                        animate={{ y: 0, scale: 0.5 }}
                        transition={{ duration: 1, type: 'spring', stiffness: 120 }}
                    >
                        <a href={logo.url}>
                            <img src={logo.src} alt="Logo" className="logo-image" />
                        </a>
                    </motion.div>
                ))}
            </div>
            <div className="logos-row" style={logoRowStyle}>
                {bottomLogos.map(logo => (
                    <motion.div
                        key={logo.id}
                        className="logo-motion-div"
                        initial={{ y: -1000, scale: 1.5 }}
                        animate={{ y: -50, scale: 0.7 }}
                        transition={{ duration: 1, type: 'spring', stiffness: 120 }}
                    >
                        <a href={logo.url}>
                            <img src={logo.src} alt="Logo" className="logo-image2" />
                        </a>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default LogosLoop;