import React from 'react';
import '../Css/Privaatsuspoliitika.css';
import Footer from '../Components/Footer';

function Kasutustingimused() {
  return (
    <div className="pea">
      <div>
        <h2>Kasutustingimused</h2>
        <div className='sisu'>
        <p>Viimati uuendatud: 28.02.2024</p>
          <p>
            Tere tulemast meie veebilehele! Enne selle kasutamist palun tutvuge järgmiste kasutustingimustega. Jätkates meie veebisaidi kasutamist, nõustute automaatselt järgmiste tingimustega:
          </p>
          <ol>
            <li>
              <p>
                <strong>Intellektuaalomand:</strong> Kõik selle veebisaidi sisu, sealhulgas tekstid, pildid, graafika, logo ja helid, on meie omand ja neid kaitsevad autoriõigused. Materjalide kopeerimine, levitamine või kasutamine muul eesmärgil ilma meie eelneva loata on keelatud.
              </p>
            </li>
            <li>
              <p>
                <strong>Kasutajate vastutus:</strong> Te vastutate kogu teabe õigsuse eest, mida te meie veebisaidile esitate. Kui teie poolt esitatud teave on vale või eksitav, võime selle teie teadmata eemaldada.
              </p>
            </li>
            <li>
              <p>
                <strong>Privaatsus:</strong> Teie privaatsus on meile oluline. Me kasutame teie isikuandmeid ainult vastavalt meie privaatsuspoliitikale. Me ei jaga teie isikuandmeid kolmandate osapooltega ilma teie eelneva loata.
              </p>
            </li>
            <li>
              <p>
                <strong>Teenuste kasutamine:</strong> Meie veebisaidil pakutavad teenused on mõeldud ettevõtetele ja isiklikuks kasutamiseks. Te nõustute kasutama teenuseid ainult seaduslikel eesmärkidel ning järgima kõiki asjakohaseid seadusi ja määrusi.
              </p>
            </li>
          </ol>
          <p>
            Kui teil on küsimusi või muresid meie kasutustingimuste kohta, võtke meiega ühendust e-posti teel <a href="mailto:info@helion.ee">info@helion.ee</a>.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Kasutustingimused;
