import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom'; 
import '../Css/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const location = useLocation();
    const currentYear = new Date().getFullYear();

    // Load language from LocalStorage
    useEffect(() => {
        const savedLang = localStorage.getItem('i18nextLng'); // Kasutame ainult i18nextLng
        if (savedLang && savedLang !== i18n.language) {
            i18n.changeLanguage(savedLang);
        }
    }, [i18n]);

    // Change language function
    const changeLanguage = (lang) => {
        if (i18n.language === lang) return; // Väldi üleliigset keelevahetust

        // Määrame keele ainult ühe võtmepõhiselt
        localStorage.setItem('i18nextLng', lang);
        i18n.changeLanguage(lang).then(() => {
            const currentPath = location.pathname.split('/');
            const newPath = `/${lang}/${currentPath.slice(2).join('/')}`; // Uuenda URL-i vastavalt keelele
            navigate(newPath, { replace: true });
        });
    };

    return (
        <footer className="footer">
            <div className="footer-content">
                <Link className="footer-brand" to={`/${i18n.language}/copyright`}>
                    <p>© {currentYear} Helikon Medikal OÜ</p>
                </Link>
                <div className="social-media-icons">
                    <a href="https://www.linkedin.com/in/helikon-medikal-01830b2ba/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
                    </a>
                </div>
                <div>
                    <button onClick={() => changeLanguage('et')} className="language-button">
                        <img src="/Lipud/estonia.webp" alt="Eesti" className="language-icon" />
                    </button>
                    <button onClick={() => changeLanguage('en')} className="language-button">
                        <img src="/Lipud/united-kingdom.webp" alt="English" className="language-icon" />
                    </button>
                    <button onClick={() => changeLanguage('ru')} className="language-button">
                        <img src="/Lipud/russias.webp" alt="Русский" className="language-icon" />
                    </button>
                    <button onClick={() => changeLanguage('fi')} className="language-button">
                        <img src="/Lipud/finland.png" alt="Finland" className="language-icon" />
                    </button>
                    <button onClick={() => changeLanguage('lv')} className="language-button">
                        <img src="/Lipud/latvia.png" alt="Latvia" className="language-icon" />
                    </button>
                    <button onClick={() => changeLanguage('lt')} className="language-button">
                        <img src="/Lipud/lithuania.png" alt="Lithuania" className="language-icon" />
                    </button>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
