import React from 'react';
import '../Css/Meist.css'; // Veenduge, et see tee on õige
import Footer from '../Components/Footer'; // Kohandage vastavalt oma projektile
import { useTranslation } from 'react-i18next';

import WelcomeIcon from '../Pictures/collaborations.png'; // Kohandage tee vastavalt oma projektile
import CommitmentIcon from '../Pictures/healthcare.png'; // Kohandage tee vastavalt oma projektile
import MissionIcon from '../Pictures/lifeline.png'; // Kohandage tee vastavalt oma projektile

function Meist() {
  const { t } = useTranslation();

  return (
    <>
      <div className="container">
        <div className="container content">
          <div>
            <h1>{t('aboutUs.title')}</h1>
          </div>
          <div className="icon-with-text">
            <img src={WelcomeIcon} alt="Welcome" className="icon" />
            <p>{t('aboutUs.welcome')}</p>
          </div>
          <div className="icon-with-text">
            <img src={CommitmentIcon} alt="Commitment" className="icon" />
            <p>{t('aboutUs.commitment')}</p>
          </div>
          <div className="icon-with-text">
            <img src={MissionIcon} alt="Mission" className="icon" />
            <p>{t('aboutUs.mission')}</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Meist;
