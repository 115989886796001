import React from 'react';
import '../Css/Privaatsuspoliitika.css';
import Footer from '../Components/Footer';

function Privaatsuspoliitika() {
  return (
    <div className="pea">
    <div >
        <h2>Privaatsuspoliitika</h2>
        <div className='sisu'>
        <p>Viimati uuendatud: 28.02.2024</p>
          <p>
            Tere tulemast meie veebilehele! Me hoolime teie privaatsusest ja soovime, et tunneksite end meie veebisaidi kasutamisel turvaliselt. Selles privaatsuspoliitikas selgitame, millist teavet me teie kohta kogume ja kuidas seda kasutame.
          </p>
          <h3>Küpsised</h3>
          <p>
            Meie veebileht võib kasutada küpsiseid parema kasutajakogemuse tagamiseks ja meie veebisaidi kasutamise analüüsimiseks. Küpsised on väikesed tekstifailid, mida teie veebibrauser salvestab teie seadmesse. Need võivad sisaldada anonüümset teavet, mis aitab meil mõista, kuidas te meie veebisaiti kasutate.
          </p>
          <p>
            Küpsiseid saab lubada või keelata oma veebibrauseri seadete kaudu. Palun märkige, et küpsiste keelamine võib mõjutada mõningaid meie veebisaidi funktsioone ja kasutuskogemust.
          </p>
          <h3>Kolmandate osapooltega lingid</h3>
          <p>
            Meie veebisait võib sisaldada linke teistele veebisaitidele, mis omavad oma privaatsuspoliitikat. Me ei vastuta nende veebisaitide sisu või privaatsustavade eest. Palun tutvuge nende veebisaitide privaatsuspoliitikaga enne nendel lehtedel surfamist.
          </p>
          <h3>Andmekaitse</h3>
          <p>
            Me ei kogu ega salvesta teie isikuandmeid, välja arvatud juhul, kui te otseselt meiega kontakteerute läbi meie veebisaidi. Me ei jaga teie isiklikke andmeid kolmandate osapooltega.
          </p>
          <h3>Muudatused privaatsuspoliitikas</h3>
          <p>
            Me võime käesolevat privaatsuspoliitikat aeg-ajalt muuta. Kõik muudatused jõustuvad kohe pärast nende avaldamist. Palun külastage regulaarselt meie veebisaiti, et olla kursis privaatsuspoliitika muudatustega.
          </p>
          <p>
            Kui teil on küsimusi või muresid meie privaatsuspoliitika kohta, võtke meiega ühendust e-posti teel <a href="mailto:info@helkion.ee">info@helikon.ee</a>.
          </p>
        </div>
    </div>
    <Footer></Footer>
    </div>
  );
}

export default Privaatsuspoliitika;
