import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';  // Firebase Firestore ühendus
import { useParams, useNavigate } from 'react-router-dom';

const EditProduct = () => {
  const { id } = useParams();  // URL-ist toote ID
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      const productDoc = doc(db, 'products', id);
      const productData = await getDoc(productDoc);
      if (productData.exists()) {
        setProduct(productData.data());
      } else {
        console.error("Toodet ei leitud");
      }
    };
    fetchProduct();
  }, [id]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const productDoc = doc(db, 'products', id);
    await updateDoc(productDoc, product);
    navigate('/admin');  // Pärast muutmist suuna tagasi admin lehele
  };

  if (!product) return <p>Laadimine...</p>;

  return (
    <div className="container mt-5">
      <h2>Muuda toodet</h2>
      <form onSubmit={handleUpdate}>
        <div className="mb-3">
          <label className="form-label">Toote nimi</label>
          <input
            type="text"
            className="form-control"
            value={product.name}
            onChange={(e) => setProduct({ ...product, name: e.target.value })}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Kirjeldus</label>
          <textarea
            className="form-control"
            value={product.description}
            onChange={(e) => setProduct({ ...product, description: e.target.value })}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Hind</label>
          <input
            type="number"
            className="form-control"
            value={product.price}
            onChange={(e) => setProduct({ ...product, price: e.target.value })}
          />
        </div>
        <button type="submit" className="btn btn-primary">Muuda toodet</button>
      </form>
    </div>
  );
};

export default EditProduct;
